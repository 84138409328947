<template>
    <div v-if="displayedMessage" id="subtitleContainer">
        <p style="padding:10px;">{{ displayedMessage }}</p>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const channel = new BroadcastChannel('game_channel');

// State to track the displayed message
const displayedMessage = ref('Creativity thrives when individuals collaborate, sharing ideas and embracing diverse perspectives.');
// Maximum number of characters per chunk, but split based on words
const chunkSize = 100;
let currentIndex = 0;
let messageChunks = [];
let displayTimeout = null;
let hideTimeout = null;

channel.onmessage = (event) => {
    try {
        console.log('Received gameStatus event in Subtitles via BroadcastChannel:', event.data);
        if (event.data.is_ended && displayedMessage.value != '') {
            displayedMessage.value = '';
        }
    } catch (error) {
        console.error("Error handling gameStatus event:", error);
    }
};

const props = defineProps({
    message: String
});

const splitMessageIntoChunks = (message) => {
    const words = message.split(' ');
    const chunks = [];
    let currentChunk = '';

    words.forEach(word => {
        // If adding the next word would exceed the chunk size, push the current chunk to the array
        if ((currentChunk + word).length <= chunkSize) {
            currentChunk += (currentChunk ? ' ' : '') + word; // Add the word to the current chunk
        } else {
            // Push the current chunk and start a new one with the current word
            chunks.push(currentChunk);
            currentChunk = word;
        }
    });

    // Push any remaining chunk that wasn't added
    if (currentChunk) {
        chunks.push(currentChunk);
    }

    return chunks;
};

// Hide the subtitles after last chunk is shown and 4 seconds elapsed
const hideSubtitles = () => {
    // Only hide if it's not the last chunk
    if (currentIndex < messageChunks.length) {
        hideTimeout = setTimeout(() => {
            displayedMessage.value = ''; // Hide the subtitle container
        }, 4000); // Wait for 4 seconds after the last chunk is shown
    }
};

const displayNextChunk = () => {
    if (currentIndex < messageChunks.length) {
        // Add ellipsis if there are more chunks to display
        displayedMessage.value = messageChunks[currentIndex] + (currentIndex < messageChunks.length - 1 ? '...' : '');
        currentIndex++;

        // Display next chunk after 6.5 seconds
        displayTimeout = setTimeout(displayNextChunk, 6500);
    } else {
        // Do not hide the last chunk, just stop further updates
        clearTimeout(hideTimeout);
    }
};

watch(() => props.message, (newMessage) => {
    if (newMessage) {
        clearTimeout(displayTimeout); // Clear any previous timeout for chunk display
        clearTimeout(hideTimeout); // Clear the previous hide timeout if any
        currentIndex = 0; // Reset the index to start from the beginning

        const msg = newMessage.split('-')[0]; // Extract the message
        messageChunks = splitMessageIntoChunks(msg); // Split message into word-based chunks

        displayNextChunk(); // Start displaying the first chunk
    }
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400..900&display=swap');

#subtitleContainer p {
    font-family: "Tektur", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    text-transform: uppercase;
}

#subtitleContainer {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
    font-size: 40px;
    font-family: "Tektur", sans-serif;
    color: rgb(18 51 138) !important;
    text-transform: uppercase;
    text-shadow: 3px 3px 6px rgba(255, 255, 255, 0.7);
    padding: 20px 25px;
    z-index: 9999;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: normal;
}

#subtitleContainer p {
    margin: 0;
    font-weight: 700;
}
</style>