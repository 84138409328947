<template>
  <AudioRecorder @speak="handleSpeak" />
  <div id="videoWrapper">
    <VideoPlayer :message="currentMessage" />
    <Subtitles :message="currentMessage" />   
  </div>

  <canvas id="waveform" width="512" height="32" style="position:absolute; bottom:0; width:256; left: calc(50% - 256); z-index: 1"></canvas>
</template>

<script setup>
import { ref } from 'vue';
import AudioRecorder from '../components/AudioRecorder.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import Subtitles from '../components/Subtitles.vue';

const currentMessage = ref('');

// Handle the 'speak' event emitted by AudioRecorder
const handleSpeak = (msg) => {
  currentMessage.value = `${msg}-${Date.now()}`;
};
</script>

<style scoped>
#videoWrapper {
  position: relative;  /* Make it a relative container for absolute positioning */
  width: 100vw;  /* Full screen width */
  height: 100vh;  /* Full screen height */
  background-color: black; /* Ensure it has a black background */
}
</style>