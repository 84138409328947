<template></template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue';
import { useAudioSocket } from '@/composables/useAudioSocket';
const channel = new BroadcastChannel('game_channel');
const aliasChannel = new BroadcastChannel('userAlias');
const gameStatusChannel = new BroadcastChannel('gameEvents');

const handleGameEvent = (event) => {
    const data = event.data;
    if (data.type === 'START_GAME') {
        console.log('Received start game event:', data);
        startNewGame();
    } else if (data.type === 'END_GAME') {
        console.log('Received end game event:', data);
        endCurrentGame();
    }
};

onMounted(() => {
    gameStatusChannel.onmessage = handleGameEvent;
});

onBeforeUnmount(() => {
    gameStatusChannel.close(); // Close the channel to clean up resources
});

const emit = defineEmits(['speak']);
const {
    startAudioRecording,
    removeConversationFromCache
} = useAudioSocket();

const startNewGame = () => {
    startAudioRecording((msg) => {
        if (msg.includes('Amazing! Thanks for playing') || msg.includes('thanks for playing')
        || msg.includes('I give up!')) {
            const displayMessage = msg + ' This game will reset in a few seconds...';
            //emit(displayMessage);
            emit('speak', displayMessage);

            setTimeout(() => {
                removeConversationFromCache();
                channel.postMessage({ is_ended: true });
                aliasChannel.postMessage({ userAlias: '' });
            }, 15000);
        } else {
            emit('speak', msg);
        }
    });
};

const endCurrentGame = () => {
    removeConversationFromCache();
    channel.postMessage({ is_ended: true });
    aliasChannel.postMessage({ userAlias: '' });
};
</script>

<style scoped>
.button-container {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}
button {
    z-index: 10;
}
</style>
  