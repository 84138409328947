import { createRouter, createWebHistory } from 'vue-router'
import AvatarView from '../views/AvatarView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/avatar',
    name: 'avatar',
    component: AvatarView
  },
  {
    path: '/minimal',
    name: 'minimalavatar',
    component: function () {
      return import('../views/MinimalView.vue')
    }
  },
  {
    path: '/waitroom',
    name: 'waitroom',
    component: function () {
      return import('../views/WaitRoomView.vue')
    }
  },
  {
    path: '/waitroomprivate',
    name: 'waitroomprivate',
    component: function () {
      return import('../views/WaitRoomPrivateView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory((typeof process === 'object' && process.env.BASE_URL ) || ''),
  routes
})

export default router
