<template>
    <div id="videoWrapper">
        <!-- Static video that plays continuously -->
        <video ref="staticVideo" autoplay loop muted playsinline preload="auto"></video>

        <!-- Streamed video that is displayed only when a stream is available -->
        <video ref="streamedVideo" style="z-index: -1;" playsinline preload="auto"></video>
    </div>
</template>

<script setup>
import { ref, watch, nextTick, onMounted, onBeforeUnmount } from 'vue';
import mpegts from 'mpegts.js';

const staticVideo = ref(null); // Ref for static video element
const streamedVideo = ref(null); // Ref for streamed video element
const streamPlayer = ref(null); // Ref for MPEG-TS player
const props = defineProps({
    message: String
});
const userAlias = ref(localStorage.getItem('userAlias') || '');
const channel = new BroadcastChannel('userAlias');

channel.onmessage = (event) => {
    console.log('Received userAlias event in VideoPlayer via BroadcastChannel:', event.data);
    userAlias.value = event.data.userAlias;
};

const handleStorageEvent = (event) => {
    if (event.key === 'userAlias') {
        console.log('Detected cross-tab alias change:', event.newValue);
        userAlias.value = event.newValue;
    }
};

import staticVideoFile from '@/assets/static.mp4'; // Path to the static video file

var connectionUrl = NaN;
if (window.location.href.indexOf('whizwiz') == -1) {
    connectionUrl = process.env.VUE_APP_MAIN_URL;
} else {
    connectionUrl = window.location.origin ;
}
         

// Ensure the static video plays on mount
onMounted(() => {
    window.addEventListener('storage', handleStorageEvent);

    if (staticVideo.value) {
        staticVideo.value.src = staticVideoFile; // Load the static video file
        staticVideo.value.play(); // Play the static video immediately
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('storage', handleStorageEvent);
    localStorage.removeItem('userAlias');
});

// Function to handle playing the streamed video
const playStreamedVideo = async (msg) => {
    await nextTick();

    if (streamPlayer.value) {
        streamPlayer.value.destroy();
        streamPlayer.value = null;
    }

    streamPlayer.value = mpegts.createPlayer({
        type: 'mpegts',
        isLive: true,
        url: `${connectionUrl}/media/file?text=${msg}`,
        config: {
            enableStashBuffer: true,  // Enable buffer to preload video before playing
            stashInitialSize: 128,    // Preload a small buffer to reduce lag
            autoCleanupSourceBuffer: true,
            isLive: true,
        }
    });

    if (streamedVideo.value) {
        streamPlayer.value.attachMediaElement(streamedVideo.value);

        // Preload the stream
        streamPlayer.value.load();

        // Wait for the video to be ready before emitting any events
        streamedVideo.value.addEventListener('canplaythrough', () => {
            bringStreamToFront(); // Bring the video stream to front
            streamPlayer.value.play().catch((e) => {
                console.error('Playback error:', e);
                bringStaticToFront();
            });
        });

        // Set up a listener to detect when the video stream has buffered sufficiently
        streamPlayer.value.on(mpegts.Events.LOADING_COMPLETE, () => {
            bringStreamToFront();  // Bring the streamed video to the front once ready
            // Start playing the video only after buffering is done
            streamPlayer.value.play().catch((e) => {
                console.error('Playback error:', e);
                bringStaticToFront();
            });
        });

        // Handle errors
        streamPlayer.value.on(mpegts.Events.LOADER_ERROR, (error) => {
            console.info('Loader error:', error);
            handleErrorAndRetry(msg);
        });

        streamPlayer.value.on(mpegts.Events.MEDIA_SOURCE_ERROR, (error) => {
            console.info('MediaSource error:', error);
            handleErrorAndRetry(msg);
        });

        streamedVideo.value.onended = () => {
            bringStaticToFront();
            if (streamPlayer.value) {
                streamPlayer.value.destroy();
                streamPlayer.value = null;
            }
        };
    }
};

let retryCount = 0;
const maxRetries = 5;

const handleErrorAndRetry = (msg) => {
    bringStaticToFront(); // Show static video on error

    if (retryCount < maxRetries) {
        retryCount++;
        console.info(`Retrying stream connection (${retryCount}/${maxRetries})...`);
        setTimeout(() => {
            playStreamedVideo(msg); // Retry streaming the video
        }, 3000); // Retry after 3 seconds
    } else {
        console.error('Maximum retries reached. Unable to resume stream.');
        retryCount = 0; // Reset retry count for future attempts
    }
};

const cleanupEventListeners = () => {
    if (streamedVideo.value) {
        streamedVideo.value.removeEventListener('canplaythrough', bringStreamToFront);
        streamedVideo.value.removeEventListener('ended', bringStaticToFront);
    }
    if (streamPlayer.value) {
        streamPlayer.value.off(mpegts.Events.LOADING_COMPLETE);
        streamPlayer.value.off(mpegts.Events.LOADER_ERROR);
        streamPlayer.value.off(mpegts.Events.MEDIA_SOURCE_ERROR);
    }
};

// Z-index swap functions to show/hide stream and static video
const bringStreamToFront = () => {
    streamedVideo.value.style.zIndex = 1;
    staticVideo.value.style.zIndex = -1;
};

const bringStaticToFront = () => {
    streamedVideo.value.style.zIndex = -1;
    staticVideo.value.style.zIndex = 1;
};

// Watch for message changes to trigger the stream
watch(() => props.message, (newMessage) => {
    if (newMessage) {
        const msg = newMessage.split('-')[0];
        playStreamedVideo(msg); // Play streamed video on message update
    } else {
        // If no message, fallback to static video
        bringStaticToFront();

        // Stop and clean up the player when there's no stream
        if (streamPlayer.value) {
            streamPlayer.value.destroy();
            streamPlayer.value = null;
        }
    }
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400..900&display=swap');

.futuristic {
    font-family: "Tektur", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}

.cyan {
    color: rgb(53, 242, 255) !important;
}

#videoWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* No scrollbars */
}

#videoWrapper video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Contain the video within the viewport */
    transition: z-index 0s ease; /* Instant z-index transition */
}
@media (orientation: portrait) {
#videoWrapper video {
    object-fit: cover !important;
}
}
/* Special handling for iPad */
@media (max-width: 1024px) {
    #videoWrapper video {
        object-fit: cover; /* Ensure the video fills the screen on smaller devices */
        width: 100vw;
        height: 100vh;
    }
}
</style>
