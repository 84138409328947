import { ref, onUnmounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { io } from 'socket.io-client';

export function useAudioSocket() {
    const conversationId = ref(null);
    let socket = null;
    let stream = null;
    let context = null;
    let recorder = null;

    const initializeConversation = () => {
        let storedConversationId = localStorage.getItem('conversation_id');
        if (storedConversationId === null) {
            storedConversationId = uuidv4();
            localStorage.setItem('conversation_id', storedConversationId);
            console.log('Creating new conversation id: ', storedConversationId);
        }
        conversationId.value = storedConversationId;
        console.log('Reusing conversation id: ', storedConversationId);
    };

    const initializeSocket = (onSpeakCallback) => {
        if (socket) {
            socket.disconnect();
            socket = null;
        }

        var connectionUrl = NaN;
        if (window.location.href.indexOf('whizwiz') == -1) {
            connectionUrl = process.env.VUE_APP_GAME_URL;
        } else {
            connectionUrl = window.location.origin+"/game";
        }
        
        socket = io(connectionUrl, {
            query: {
                conversation_id: conversationId.value
            },
            transports: ['websocket'],  // Force WebSocket only
            upgrade: false  // Disable upgrading from polling
        });
        console.log('connecting to ' + connectionUrl); 

        socket.on('connect', () => console.log('Connected to Socket.IO server'));
        socket.on('disconnect', () => console.warn('Disconnected from Socket.IO server'));
        socket.on('speak', (msg) => {
            onSpeakCallback(msg);
        });

        socket.on("connect_error", (err) => {
            console.error(`Connection error: ${err.message}`);
            console.error('Error details:', err);
        });

        socket.on('error', (err) => {
            console.error('Socket.IO error:', err);
        });
    };

    const startAudioRecording = (onSpeakCallback) => {
        initializeConversation();
        initializeSocket(onSpeakCallback);

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            
            navigator.mediaDevices.getUserMedia({
              audio: {
                noiseSuppression: false,
                echoCancellation: false,
                autoGainControl: false
              },
            })
              .then((mediaStream) => {
                stream = mediaStream;

                if (context && context.state !== 'closed') {
                    context.close();
                }
    
                context = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 16000 });                
                const audioInput = context.createMediaStreamSource(mediaStream);
    
                recorder = context.createScriptProcessor(1024, 1, 1);
                recorder.onaudioprocess = (e) => {
                  const data = e.inputBuffer.getChannelData(0);
                  const buf = new Int16Array(data.length);
                  for (let i = 0; i < data.length; i++) {
                    buf[i] = Math.max(-32768, Math.min(32767, data[i] * 32768));
                  }
                  socket.emit('audio_event', {
                      conversation_id: conversationId.value,
                      audio_chunk: buf.buffer
                  });
                };
                const analyserNode = new AnalyserNode(context);
                //check canvasElt is defined
                const canvasElt = document.getElementById('waveform');
                const WIDTH = canvasElt.width;
                const HEIGHT = canvasElt.height;
                const intendedWidth = canvasElt.clientWidth;
                canvasElt.setAttribute("width", intendedWidth);     
                const javascriptNode = context.createScriptProcessor(1024,1,1);
                javascriptNode.onaudioprocess = () => {
                  const bufferLength = analyserNode.frequencyBinCount;
                  const dataArray = new Uint8Array(bufferLength);
                  // Get the time domain data for this sample
                  analyserNode.fftSize = 32;
    
                  // Draw the display when the audio is playing
                  if (context.state === "running") {
                    // Draw the time domain in the canvas
                    requestAnimationFrame(() => {
                      // Get the canvas 2d context
                      const canvasCtx = canvasElt.getContext("2d");
                      analyserNode.getByteFrequencyData(dataArray);
    
                      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
                      const barWidth = (WIDTH / bufferLength) ;
                      let barHeight;
                      let x = 0;
                      for (let i = 0; i < bufferLength; i++) {
                          barHeight = dataArray[i] / 4;
    
                          canvasCtx.fillStyle = `rgb(150 150 ${barHeight * 4 + 150})`;
                          canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);
     
                          x += barWidth + 1;
                        }
                    });
                  }
                };
                audioInput.connect(analyserNode);
                analyserNode.connect(javascriptNode);
                analyserNode.connect(recorder);
                javascriptNode.connect(context.destination);
                recorder.connect(context.destination);
    
                
    
              })
              .catch((err) => console.error('Error accessing user media:', err));
    
          } else {
            alert('media devices unavailable');
          }
    };

    const removeConversationFromCache = () => {
        cleanup();

        let storedConversationId = localStorage.getItem('conversation_id');
        let userAlias = localStorage.getItem('userAlias');

        if (storedConversationId !== null) {
            localStorage.removeItem('conversation_id');
            localStorage.removeItem('userAlias');
            console.log('Deleted conversation id: ', storedConversationId);
        }

        if (userAlias !== null) {
            localStorage.removeItem('userAlias');
            console.log('Deleted user alias: ', userAlias);
        }
    };

    const cleanup = () => {
        if (socket) {
            socket.disconnect();
            socket = null;
        }
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            stream = null;
        }

        const videoElements = document.querySelectorAll('video');  // Select any video elements
        videoElements.forEach(video => {
            video.load();  // Reload the video element to finalize stopping
            console.log('Video stream stopped');
        });

        if (recorder) {
            recorder.disconnect();
            recorder = null;
        }
        if (context && context.state !== 'closed') {
            context.close();
            context = null;
        }
    };    

    onUnmounted(cleanup);

    return {
        startAudioRecording,
        removeConversationFromCache,
        conversationId
    };
}